<template>
  <div class="ServiceHallDetails" v-if="dataLoaded">
    <div class="banner Hall-flex">
      <div class="Hall-left">
        <div class="goods-info">
          <div class="goods-title">{{ DetailsInfo.serviceTitle }}</div>
          <div class="goods-lable">
            <div class="lable">{{ DetailsInfo.serviceLabel }}</div>
            <div class="sales">
              <img
                  src="@/assets/imgs/xiaoliang.png"
                  alt
                  style="width: 16px; height: 16px; margin-right: 4px"
              />
              总销量:{{ DetailsInfo.orderNum || 0 }}
            </div>
          </div>
          <div class="goods-magnifier">
            <!-- 视频播放 -->
            <div
                class="goods-videoPlay"
                v-if="BannerIndex == 0 && DetailsInfo.serviceVideo"
            >
              <video
                  :src="DetailsInfo.serviceVideo"
                  muted
                  autoplay="false"
                  controls
              ></video>
            </div>
            <!-- 放大镜 -->
            <Magnifier @GetzIndex="GetzIndex" :CoverUrl="BannerCover" v-else />
            <!--  -->
            <!-- <List1 Types="list2" v-if="DetailsInfo.serviceImgs" :Num="5" :DataList="DetailsInfo.serviceImgs" :Config="{
                width:'111px',
                height: '131px',
                minHeight:'111px',
                indexs:this.BannerIndex
            }" @GetImageBack="GetImageBack" />-->
            <div class="imagevideo">
              <!-- 视频 -->
              <div
                  class="goods-video"
                  v-if="DetailsInfo.serviceVideo"
                  @mouseenter="mouseoutImage(DetailsInfo.serviceVideo)"
              >
                <div class="video-zzc"></div>
                <video :src="DetailsInfo.serviceVideo" autoplay="false"></video>
              </div>
              <!-- 图片 -->
              <div
                  class="goods-image"
                  v-for="(item, index) in DetailsInfo.serviceImgs"
                  :key="index"
              >
                <img
                    :src="item"
                    alt
                    @mouseenter="mouseoutImage(item, 'image', index)"
                />
              </div>
            </div>
            <div class="config">
              <div class="config-left">
                <div class="share">
                  <el-dropdown @command="share">
                    <span class="share-text"><i class="el-icon-share"></i>分享</span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="wechat">微信</el-dropdown-item>
                      <el-dropdown-item command="weibo">微博</el-dropdown-item>
                      <el-dropdown-item command="qq">QQ</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div>
                  <img src="@/assets/imgs/shouchang.png" alt />
                  <div style="font-size: 16px" @click="collect">收藏商家</div>
                  <!-- （206人气） -->
                  <span class="pop"></span>
                </div>
              </div>
              <div class="config-right">
                <div>雇佣须知</div>
                <div @click="ComplaintsReport">投诉举报</div>
              </div>
            </div>
          </div>
        </div>
        <div class="el-tab-info">
          <div class="el-tabs__nav-scroll">
            <div class="goods-el-tabs banner">
              <div
                  class="items"
                  :class="index == ElTabsindex ? 'items-active' : ''"
                  v-for="(item, index) in ElTabs"
                  :key="index"
                  @click="ElTabsClick(index, item)"
              >
                {{ item.name }}
                <a href></a>
              </div>
            </div>
          </div>
          <div class="goods-tabs">
            <div>
              <!-- 服务信息 -->
              <div class="box-about" id="key1">
                <div class="title">服务介绍</div>
                <div class="txt">
                  <vHtml :Html="DetailsInfo.provider.providerContents" class="html" />
                </div>
              
              </div>
              
              <!-- 服务介绍 -->
              <div class="box-about" id="key2">
                <div class="title">服务信息</div>
                <div class="txt-info">
                  <div class="txt_items txt-border">
                    <div class="left txt-left">服务信息</div>
                    <div class="right right-txt">
                      <div class="right-lable txt-border2">
                        <div class="name">服务平台</div>
                        <div class="types">
                          {{ DetailsInfo.platform.platformName }}
                        </div>
                      </div>
                      <div class="right-lable">
                        <div class="name">服务类目</div>
                        <div class="types">
                          {{ DetailsInfo.category.label }}
                        </div>
                      </div>
                      <div class="right-lable right-lable-border">
                        <div class="name">服务内容</div>
                        <div class="types">{{ DetailsInfo.items.itemsName }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="txt_items txt-borders">
                    <div class="left txt-left">效率信息</div>
                    <div class="right right-txt">
                      <div class="right-lable">
                        <div class="name">根据单位</div>
                        <div class="types">
                          {{ DetailsInfo.efficiencyUnit }}
                        </div>
                      </div>
                      <div class="right-lable">
                        <div class="name">参考数量</div>
                        <div class="types">
                          {{ DetailsInfo.efficiencyNumber }}
                        </div>
                      </div>
                      <div class="right-lable right-lable-border">
                        <div class="name">交付时间</div>
                        <div class="types">
                          {{ DetailsInfo.efficiencyTime }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="txt_items txt-border1">
                    <div class="left">其它信息</div>
                    <div class="right right-txt">
                      <div class="right-lable right-lable-border">
                        <div class="name">提供人数</div>
                        <div class="types">
                          {{ DetailsInfo.servicePeople }}人
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
              </div>
              
              <!-- 服务详情 -->
              <div class="box-about" id="key3">
                <div class="title">服务详情</div>
                <div class="txt">
                  <vHtml :Html="DetailsInfo.serviceContents" class="html" />
                </div>
                <!-- 服务流程 -->
                <div class="title">服务流程</div>
                <FlowPath />
              </div>
              
              <div class="box-about" style="margin-top: 60px" id="key4">
                <div class="title">服务评价</div>
                <Evaluate
                    :evaluate="DetailsInfo.service_order_evaluate_list"
                    :serviceTitle="DetailsInfo.serviceTitle"
                    v-if="DetailsInfo?.service_order_evaluate_list"
                />
                <!-- 暂无评价 -->
                <!-- <el-empty :image="EvaluateImage" description="暂无评价~"></el-empty> -->
                
                <!-- <div v-show="this.showEmpty==0?'true':'false'">
                  <el-empty :image="EvaluateImage" :image-size="70" description="暂无评价~"></el-empty>
                </div>-->
                <!-- <el-empty v-show="false" :image="EvaluateImage" :image-size="70" description="暂无评价~"></el-empty> -->
                <el-empty
                    v-show="showEmpty"
                    :image="EvaluateImage"
                    :image-size="70"
                    description="暂无评价~"
                ></el-empty>
                <!-- <el-empty v-show="showEmpty=true" :image="EvaluateImage" :image-size="70" description="暂无评价~"></el-empty> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Hall-right goods-info" :style="{top: stickyHeight}">
        <div
            class="goods-details"
            :style="{zIndex: zIndex}"
        >
          <div class="goods-details-width">
            <!-- <div class="goods-title">{{DetailsInfo.serviceTitle}}</div>
            <div class="goods-lable">
              <div class="lable">{{DetailsInfo.serviceLabel}}</div>
              <div class="sales">总销量:{{DetailsInfo.servicePeople}}</div>
            </div>-->
            <div class="goods-shoping">
              <div class="goods-shop-info">
                <div class="goods-shop-head">
                  <el-avatar
                      :size="75"
                      :src="DetailsInfo.user.avatarImg"
                  ></el-avatar>
                  <div class="goods-shop-nickname">
                    <div class="nickname">
                      <div>{{ DetailsInfo.provider.providerName }}</div>
                      <span v-if="DetailsInfo.provider.providerLabelTxt">{{
                          DetailsInfo.provider.providerLabelTxt
                        }}</span>
                      <!-- <span>综合评分5.0</span> -->
                    </div>
                    <div class="sort">
                      准时交付率80%/3分钟回复率66%
                      <span>综合评分5.0</span>
                    </div>
                  </div>
                </div>
                <!-- 进入主页 -->
                <div class="toUserInfo" @click="GetServiceHome">
                  进入主页
                  <i class="el-icon-arrow-right"></i>
                </div>
                <!-- 关注 -->
                <!-- <div class="coll">关注</div> -->
              </div>
              <div class="goods-from">
                <div class="goods-txt">
                  <div class="name">服务等级：</div>
                  <div class="rule-img">
                    <div class="vip">VIP{{ DetailsInfo.provider.providerGrade }}</div>
                    <div class="phone">
                      <img src="@/assets/imgs/demand/phone.png" alt />
                      <div>手机已认证</div>
                    </div>
                    <div class="code">
                      <img src="@/assets/imgs/demand/code.png" alt />
                      <div>身份已认证</div>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div class="goods-txt-flex">
                  <div class="goods-txt">
                    <div class="name">服务承诺：</div>
                    <div class="rule rule-color">
                      <span v-if="DetailsInfo.provider.providerType === 1" class="ger"
                      >个人</span
                      >
                      <span v-if="DetailsInfo.provider.providerType === 2" class="qiye"
                      >企业</span
                      >
                      <span class="price"
                      >保证金{{
                          DetailsInfo.provider.providerType === 1 ? '1000' : '2000'
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="goods-txt">
                    <!-- <div class="name">售后保障：</div> -->
                    <!-- <div class="rule rules">
                      <span>免费修改{{DetailsInfo.updateCount}}次</span>
                    </div>-->
                  </div>
                </div>
                <!--  -->
                <div class="goods-txt">
                  <div class="name">优惠活动：</div>
                </div>
                
                <div class="goods-txt">
                  <div class="name">售后保障：</div>
                  <div class="rule rules">
                    <span>七天无理由修改3次</span>
                  </div>
                </div>
                <!--  -->
                <!--  -->
                <div class="goods-txt">
                  <div class="name">雇佣限制：</div>
                  <div class="rule rules">
                    <span style="margin-right: 10px"
                    >{{ DetailsInfo.shortServiceTimeInt }}{{ serviceTimeUnit }}起雇</span
                    >
                    <span style="margin-right: 10px"
                    >可雇时段：{{ DetailsInfo.serviceTime }}</span
                    >
                    <span>撤单率：20%</span>
                  </div>
                </div>
                <!-- <div class="goods-txt">
                  <div class="name">个&#8195;人&#8195;描&#8195;述：</div>
                  <div class="rule rules">
                    <span>{{DetailsInfo.serviceDescribe}}</span>
                  </div>
                </div>-->
              </div>
            </div>
            <el-popover
                ref="serviceTimeUnitRef"
                placement="bottom"
                width="466"
                trigger="hover">
              <div class="service-amount-item">
                <div class="service-amount-option" slot="reference"
                     @click="clickServiceTimeUnit(item)"
                     v-for="item in serviceTimeArr" :key="item[0]">
                  <div class="price">
                    <span>￥</span>
                    <span class="price-number">{{ item[1] }}</span>
                    <span>元</span>
                    <span>/</span>
                    <span>{{ item[0] }}</span>
                  </div>
                  <div class="tips">
                    <span>未开始工作前 享受急速退款</span>
                    <img src="@/assets/imgs/demand/phone.png" alt />
                  </div>
                  <span v-if="selectedServiceTimeUnit[0] === item[0]" class="wm-radio"></span>
                
                </div>
              </div>
              <div class="service-amount-option hover" slot="reference">
                <div class="price">
                  <span>￥</span>
                  <span class="price-number">{{ selectedServiceTimeUnit[1] }}</span>
                  <span>元</span>
                  <span>/</span>
                  <span>{{ selectedServiceTimeUnit[0] }}</span>
                </div>
                <div class="tips">
                  <span>未开始工作前 享受急速退款</span>
                  <img src="@/assets/imgs/demand/phone.png" alt />
                </div>
                <span class="wm-radio"></span>
              
              </div>
            </el-popover>
            
            <!-- 介绍 -->
            <!-- 48小时内 -->
            <div class="goods-desc">现在下单，立即开启服务</div>
            <!-- 商品操作 -->
            <div class="goods-hands">
              <!-- <div class="toShop">
                <img src="@/assets/imgs/demand/shop.png" alt />
                <div class="toShop-txt">加入购物车</div>
              </div>-->
              <div class="shop-num">
                <el-input-number
                    v-model="shopNum"
                    :step="1"
                    :min="shopNumMin"
                    step-strictly
                ></el-input-number>
              </div>
              <div class="clickTo">
                <div class="chat" @click="clickShowChat">在线咨询</div>
                <div class="buy" @click="GetOrder">立即雇用</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </div>
</template>
<script>
import Magnifier from '@/components/Magnifier/index.vue'
import FlowPath from '@/components/ServiceHallDetails/FlowPath.vue'
import Evaluate from '@/components/ServiceHallDetails/Evaluate.vue'
import vHtml from '@/components/vHtml/index.vue'
import { mapActions } from 'vuex'
import { SERVICE_AMOUNT_TYPE } from '@/constants/service'
// import List1 from "@/components/List/List1.vue";
// import {toQQ, toWeibo, toWechat} from "@/assets/js/share";

export default {
  name: 'ServiceHallDetails',
  data() {
    return {
      zIndex:      8,
      ElTabsindex: 0,
      showEmpty:   true,
      ElTabs:      [
        {
          name: '服务介绍',
          key:  'key1',
        },
        {
          name: '服务信息',
          key:  'key2',
        },
        
        {
          name: '服务详情',
          key:  'key3',
        },
        {
          name: '服务流程',
          key:  'key4',
        },
      ],
      dataLoaded:  false,
      DetailsInfo: '',
      ServiceInfo: '',
      BannerIndex: 0, //轮播图默认选中 第一个
      BannerCover: '', ///封面图地址
      shopNum:     1,
      shopNumMin:  1,
      // 发布服务时设置的最短雇期类型，分：小时、天、周、月
      serviceTimeUnit:         '',
      serviceTimeUnitIndex:    null,
      serviceTimeArr:          [],
      selectedServiceTimeUnit: [],
      UNIT:                    SERVICE_AMOUNT_TYPE,
      shopType:                null,
      shopPrice:               null,
      EvaluateImage:           require('@/assets/imgs/evaluate.png'),
    }
  },
  components: {
    
    Magnifier,
    FlowPath,
    Evaluate,
    vHtml,
    // List1,
  },
  created() {
    this.GetDetails()
  },
  computed: {
    stickyHeight() {
      return this.$store.getters.getStickyHeight
    },
  },
  methods:  {
    ...mapActions(['showChat']),
    // handleClick() {},
    GetzIndex(type) {
      this.zIndex = type ? 8 : 3
    },
    ElTabsClick(index, item) {
      this.ElTabsindex = index
      this.liClick(item.key)
    },
    GetImageBack(data) {
      // console.log(data);
      this.BannerIndex = data.index
      this.BannerCover = data.item
    },
    // 鼠标移入
    mouseoutImage(item, type, index) {
      if (type === 'image') {
        this.BannerIndex = Number(1 + index)
      } else {
        this.BannerIndex = 0
      }
      this.BannerCover = item
    },
    liClick(key) {
      let targetEle = document.querySelector(`#${key}`)
      let height = 128 + 102
      window.scrollTo({
        top:      targetEle.offsetTop - height,
        behavior: 'smooth',
      })
    },
    // 获取 需求 大厅 详情 信息
    GetDetails() {
      this.dataLoaded = false
      let url = `/app-api/hiring/push-service/get?id=${this.$route.query.id}`
      this.$axios
          .get(url)
          .then((res) => {
            // if (res.data.data?.serviceOrderEvaluateList?.length !== 0) {
            //   this.showEmpty = false;
            // }
            if (res.data.code === 0) {
              let detailInfo = res.data.data
              // 给套餐 赋值
              this.shopPrice = detailInfo.serviceAmountHour
              this.shopType = 'hour'
              // 获取发布服务时设置的最短雇期类型，分：小时、天、周、月
              let key = Object.keys(this.UNIT).find(key => this.UNIT[key].index === detailInfo.shortServiceTimeType)
              if (key) {
                this.serviceTimeUnit = this.UNIT[key].label
                this.serviceTimeUnitIndex = this.UNIT[key].index
              }
              //
              this.serviceTimeArr = []
              switch (detailInfo.shortServiceTimeType) {
                case 0:
                  this.serviceTimeArr.push(
                      ['小时', detailInfo.serviceAmountHour, 'hour']
                      , ['天', detailInfo.serviceAmountDay, 'day']
                      , ['周', detailInfo.serviceAmountWeek, 'week']
                      , ['月', detailInfo.serviceAmountMonth, 'month']
                  )
                  break
                case 1:
                  this.serviceTimeArr.push(
                      ['天', detailInfo.serviceAmountDay, 'day']
                      , ['周', detailInfo.serviceAmountWeek, 'week']
                      , ['月', detailInfo.serviceAmountMonth, 'month']
                  )
                  break
                case 2:
                  this.serviceTimeArr.push(
                      ['周', detailInfo.serviceAmountWeek, 'week']
                      , ['月', detailInfo.serviceAmountMonth, 'month']
                  )
                  break
                case 3:
                  this.serviceTimeArr.push(
                      ['月', detailInfo.serviceAmountMonth, 'month']
                  )
                  break
              }
              this.selectedServiceTimeUnit = this.serviceTimeArr[0]
              this.shopNum = detailInfo.shortServiceTimeInt
              this.shopNumMin = detailInfo.shortServiceTimeInt
              // 视频 和 图片 拼合
              // 如果没有 视频的情况下
              if (detailInfo.serviceImg) detailInfo.serviceImgs = detailInfo.serviceImg.split(',')
              this.BannerCover = detailInfo.serviceImgs[this.BannerIndex]
              // this.GetService(res.data.data.userId);
              let serviceProviderLabels = this.$store.getters.getConfig.serviceProviderLabels
              // if (!serviceProviderLabels) return data;
              let tmp = serviceProviderLabels.find(item => item.value === detailInfo.provider.providerLabel)
              if (tmp) detailInfo.provider.providerLabelTxt = tmp.label
              
              this.DetailsInfo = detailInfo
              
              this.dataLoaded = true
            }
          })
          .catch((err) => {
            return Promise.reject(err)
          })
    },
    // 获取 需求 大厅 服务商 信息
    // 选择金额
    GetPrice(price, type) {
      if (type === 'hour') {
        this.shopNum = this.DetailsInfo.shortServiceTime
        this.shopNumMin = this.DetailsInfo.shortServiceTime
      } else {
        this.shopNum = 1
        this.shopNumMin = 1
      }
      this.shopPrice = price
      this.shopType = type
    },
    // 下单
    GetOrder() {
      if (!this.$store.getters.getUser || !this.$store.getters.getUser.id) {
        this.$notify.warning('您还未登录，请先登录')
        return
      }
      // 自己不能下单
      if (this.$store.getters.getUser.id === this.ServiceInfo.userId)
        return this.$modal.msgWarning('禁止给自己下单!')
      let url = '/app-api/hiring/service-order/submit'
      let payload = {
        orderUserId:       this.$store.getters.getUser.id,
        serviceId:         this.$route.query.id,
        serviceAmountType: this.shopType,
        orderNum:          this.shopNum,
        orderAmount:       this.shopPrice * this.shopNum
      }
      this.$axios
          .post(url, payload)
          .then((res) => {
            if (res.data.code === 0) {
              this.$modal.notifySuccess('订单创建成功!')
              // 跳转到 付款页面
              this.$router.push({
                path:  '/Order/Payment',
                query: { id: res.data.data },
              })
            }
          })
          .catch((err) => {
            return Promise.reject(err)
          })
    },
    GetServiceHome() {
      this.$router.push({
        path:  '/Service/Home',
        query: { id: this.DetailsInfo.userId },
      })
    },
    ComplaintsReport() {
      this.$router.push({ path: '/Report' })
    },
    
    clickShowChat() {
      if (!this.$store.getters.getUser || !this.$store.getters.getUser.id) {
        this.$notify.warning('您还未登录，请先登录')
        return
      }
      if (this.ServiceInfo.userPhone === this.$store.getters.getUser.userPhone) {
        this.$notify.info('您不能向自己在线咨询！')
        return
      }
      this.showChat({
        show:  true,
        phone: this.ServiceInfo.userPhone,
        goods: {
          id:   this.DetailsInfo.id,
          name: this.DetailsInfo.serviceTitle,
          desc: this.DetailsInfo.serviceDescribe,
          img:  this.BannerCover
        }
      })
      
    },
    // TODO 收藏，接口地址待确定
    collect() {
      // let url = `/hiring_app_api/api/pushService/getById?id=${this.$route.query.id}`;
      // this.$axios
      //     .get(url)
      //     .then((res) => {
      //       if (res.data.code === 0) {
      //         this.$message.success('收藏成功');
      //       }
      //     })
      //     .catch((err) => {
      //       return Promise.reject(err);
      //     });
    },
    share(plat) {
      console.log(plat)
      // switch (plat) {
      //   case 'weibo':
      //     toWeibo(window.location.href, this.DetailsInfo.serviceTitle)
      //     break;
      //   case 'wechat':
      //     toWechat(window.location.href, this.DetailsInfo.serviceTitle)
      //     break;
      //   case 'qq':
      //     toQQ(window.location.href, this.DetailsInfo.serviceTitle)
      //     break;
      // }
    },
    clickServiceTimeUnit(item) {
      this.selectedServiceTimeUnit = item
      this.$refs.serviceTimeUnitRef.doClose()
      let type = item[2]
      let price = item[1]
      if (type === 'hour') {
        this.shopNum = this.DetailsInfo.shortServiceTime
        this.shopNumMin = this.DetailsInfo.shortServiceTime
      } else {
        this.shopNum = 1
        this.shopNumMin = 1
      }
      this.shopPrice = price
      this.shopType = type
    }
  },
}
</script>
<style lang="less" scoped>
/deep/ .el-carousel__container {
  height: 100% !important;
}

.goods-txt-flex {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  width: 100%;
  
  .goods-txt:nth-of-type(2) {
    margin-left: 20px;
  }
}

.Hall-flex {
  display: flex;
  justify-content: space-between;
}

.Hall-left {
  width: 931px;
}

.Hall-right {
  width: 494px !important;
  padding: 14px !important;
  height: 100%;
  position: sticky !important;
}

.el-tab-info {
  border-radius: 8px 8px 8px 8px;
  width: 100%;
  background: #ffffff;
  margin-top: 30px;
}

.el-tabs__nav-scroll {
  // position: sticky;
  // top: 88px;
  // z-index: 66;
  height: 76px;
  width: 100%;
  display: flex;
  align-items: center;
  background: #ffffff;
  // background: #f7f9fa;
  .goods-el-tabs {
    display: flex;
    align-items: center;
    padding: 0 40px;
    height: 100%;
    box-sizing: border-box;
    border-bottom: 2px solid #d9d9d9;
    
    .items {
      color: #999999;
      font-size: 20px;
      margin-right: 60px;
      cursor: pointer;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      
      &:hover {
        color: #fc3737 !important;
      }
    }
    
    .items-active {
      color: #fc3737 !important;
      
      &::after {
        content: "";
        position: absolute;
        bottom: -1px;
        width: 100%;
        height: 2px;
        background: #f41e1e;
        left: 0;
      }
    }
  }
}

.goods-magnifier {
  // height: 645px;
  //   width: 598px;
  margin-top: 24px;
  
  .goods-videoPlay {
    // width: 594px;
    // height: 457px;
    width: 871px;
    height: 465px;
    background: #d9d9d9;
    border-radius: 4px 4px 4px 4px;
    position: relative;
    margin-right: 20px;
    overflow: hidden;
    
    video {
      width: 100%;
      height: 100%;
    }
  }
  
  .imagevideo {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 18px;
    
    .goods-video {
      width: 111px;
      height: 111px;
      background: #d9d9d9;
      margin-right: 10px;
      border-radius: 4px;
      cursor: pointer;
      box-sizing: border-box;
      position: relative;
      
      &:hover {
        border: 1px solid #f41e1e;
      }
      
      .video-zzc {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 5;
      }
      
      video {
        width: 111px;
        height: 111px;
      }
    }
    
    .goods-image {
      display: flex;
      align-items: center;
      
      img {
        width: 111px;
        height: 111px;
        background: #d9d9d9;
        margin-right: 10px;
        border-radius: 4px;
        cursor: pointer;
        box-sizing: border-box;
        
        &:hover {
          border: 1px solid #f41e1e;
        }
      }
    }
  }
  
  .config {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
    
    .config-left {
      display: flex;
      align-items: center;
      
      .share {
        
        margin-right: 30px;
        
        .share-text {
          font-size: 16px;
          cursor: pointer;
        }
      }
      
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      
      div {
        font-weight: 400;
        color: #000000;
        line-height: 23px;
        font-size: 20px;
        display: flex;
        align-items: center;
      }
    }
    
    .config-right {
      display: flex;
      align-items: center;
      
      div {
        font-weight: 400;
        color: #999999;
        line-height: 23px;
        font-size: 16px;
        margin-left: 30px;
        cursor: pointer;
      }
    }
  }
}

.ServiceHallDetails {
}

.goods-title {
  font-weight: bold;
  color: #000000;
  line-height: 28px;
  font-size: 24px;
}

.goods-lable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  
  .lable {
    color: #fc3737;
    line-height: 19px;
    font-size: 16px;
  }
  
  .sales {
    color: #fc3737;
    line-height: 19px;
    font-size: 16px;
  }
}

.goods-info {
  width: 100%;
  //   margin-top: 30px;
  padding: 30px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 4px 4px 1px rgba(204, 226, 237, 0.5);
  border-radius: 8px 8px 8px 8px;
  //   display: flex;
  position: relative;
  
  .goods-details {
    // position: absolute;
    // right: 0;
    // width: calc(100% - 594px - 30px - 30px);
    // top: 0;
    // padding: 40px 0;
    // box-sizing: border-box;
    // z-index: 3;
    width: 100%;
    
    .goods-details-width {
      width: 100%;
    }
    
    .goods-shoping {
      width: 100%;
      // height: 299px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #cdcdcd;
      // margin-top: 20px;
      padding: 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
      
      .goods-shop-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .goods-shop-head {
          display: flex;
          align-items: center;
          
          .goods-shop-nickname {
            margin-left: 11px;
            
            .nickname {
              display: flex;
              align-items: center;
              
              div {
                font-weight: 400;
                color: #000000;
                line-height: 28px;
                font-size: 24px;
                max-width: 160px;
                /* 1.文字强制一行显示 */
                white-space: nowrap;
                /* 2.溢出部分隐藏 */
                overflow: hidden;
                /* 3.溢出部分用省略号代替 */
                text-overflow: ellipsis;
              }
              
              span:nth-of-type(1) {
                font-weight: 400;
                color: #f41e1e;
                font-size: 12px;
                margin-left: 8px;
                border: 1px solid #fc3737;
                border-radius: 4px;
                padding: 0 5px;
              }
              
              span:nth-of-type(2) {
                font-weight: 400;
                color: #f41e1e;
                line-height: 16px;
                font-size: 14px;
                margin-left: 8px;
              }
            }
            
            .sort {
              font-weight: 400;
              color: #999999;
              line-height: 16px;
              font-size: 14px;
              margin-top: 15px;
              
              span {
                font-weight: 400;
                color: #f41e1e;
                line-height: 16px;
                font-size: 14px;
                margin-left: 8px;
              }
            }
          }
        }
        
        .toUserInfo {
          font-weight: 400;
          color: #000000;
          line-height: 19px;
          font-size: 14px;
          cursor: pointer;
          margin-top: 40px;
          position: absolute;
          right: 30px;
          top: 4px;
        }
        
        .coll {
          width: 80px;
          height: 36px;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #cdcdcd;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          color: #000000;
          line-height: 19px;
          font-size: 16px;
          cursor: pointer;
          
          &:hover {
            background: #f41e1e;
            color: #ffffff;
            border: none;
          }
        }
      }
      
      .goods-from {
        width: 100%;
        
        .goods-txt {
          display: flex;
          align-items: center;
          margin-top: 10px;
          
          .name {
            font-weight: 400;
            color: #999999;
            line-height: 18px;
            font-size: 15px;
            white-space: nowrap;
            // width: 99px;
          }
          
          .rules {
            span {
              font-weight: 400;
              color: #999999;
              line-height: 16px;
              font-size: 15px;
              // margin-right: 10px;
            }
          }
          
          .rule {
            display: flex;
            align-items: center;
            // span {
            //   font-weight: 400;
            //   color: #999999;
            //   line-height: 16px;
            //   font-size: 14px;
            //   margin-right: 10px;
            // }
            .ger {
              width: 30px;
              height: 10px;
              background: #f46700;
              border-radius: 2px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 400;
              color: #ffffff;
              line-height: 14px;
              font-size: 12px;
              margin-right: 14px;
            }
            
            .qiye {
              width: 24px;
              height: 10px;
              background: #3d8bff;
              border-radius: 2px;
              font-weight: 400;
              color: #ffffff;
              line-height: 10px;
              font-size: 10px;
              margin-right: 14px;
            }
            
            .price {
              width: 65px;
              height: 10px;
              background: #36a04c;
              border-radius: 2px;
              font-weight: 400;
              color: #ffffff;
              line-height: 14px;
              font-size: 12px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          
          .rule-img {
            display: flex;
            align-items: center;
            
            .vip {
              padding: 0 8px;
              // height: 20px;
              background: #fff2f2;
              border-radius: 3px 3px 3px 3px;
              border: 1px solid #fc3737;
              font-weight: 400;
              color: #ff9f9f;
              // line-height: 19px;
              font-size: 12px;
            }
            
            .phone img {
              width: 12px;
              height: 17px;
            }
            
            .code img {
              width: 12px;
              height: 17px;
            }
            
            .phone,
            .code {
              margin-left: 20px;
              display: flex;
              align-items: center;
              
              div {
                font-weight: 400;
                color: #999999;
                line-height: 19px;
                font-size: 16px;
                margin-left: 4px;
              }
            }
          }
          
          .rule-color {
            span {
              color: #fc3737;
              font-size: 12px;
              background: #fde8e8;
              border-radius: 4px 4px 4px 4px;
              padding: 4px 4px;
              margin-right: 9px !important;
            }
          }
        }
      }
    }
    
    .goods-price {
      display: flex;
      margin-top: 20px;
      flex-wrap: wrap;
      
      .goods-price-item {
        width: 112px;
        height: 101px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #cdcdcd;
        margin-right: 6px;
        position: relative;
        cursor: pointer;
        box-sizing: border-box;
        
        &:nth-child(4n) {
          margin-right: 0 !important;
        }
        
        &:hover {
          border: 1px solid #fc3737;
          
          .price {
            color: #fc3737;
          }
          
          // &::after {
          //   content: "";
          //   position: absolute;
          //   right: 0;
          //   top: 0;
          //   width: 27px;
          //   height: 26px;
          //   background: url("../assets/imgs/demand/active.png") no-repeat center;
          //   background-size: cover;
          // }
          .goods-price-item-day {
            background: #ffe6e6 !important;
          }
        }
      }
      
      .goods-prices {
        height: calc(100% - 35px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        .price {
          span {
            font-size: 14px;
            // margin-right: 4px;
          }
          
          font-size: 24px;
          font-weight: bold;
          // color: #fc3737;
          color: #333333;
          line-height: 19px;
        }
        
        .min-price {
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          margin-top: 10px;
          font-size: 14px;
        }
        
        .min-price-line {
          text-decoration: line-through;
        }
      }
      
      .goods-price-item-day {
        width: 100%;
        height: 35px;
        background: #fafafa;
        border-radius: 0px 0px 3px 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
      
      .goods-price-active {
        border: 1px solid #f41e1e;
        
        .price {
          color: #fc3737;
        }
        
        &::after {
          content: "";
          position: absolute;
          // right: 0;
          // top: 0;
          left: 0;
          top: 0;
          width: 21px;
          height: 20px;
          background: url("../assets/imgs/demand/active.png") no-repeat center;
          background-size: cover;
        }
        
        .goods-price-item-day {
          background: #ffe6e6 !important;
        }
      }
    }
    
    .goods-desc {
      font-weight: 400;
      color: #999999;
      line-height: 23px;
      font-size: 16px;
      margin-top: 18px;
    }
    
    .goods-hands {
      margin-top: 22px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      .toShop {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 24px;
        
        img {
          width: 29px;
          height: 28px;
        }
        
        .toShop-txt {
          font-weight: 400;
          color: #999999;
          line-height: 19px;
          font-size: 16px;
          margin-top: 2px;
        }
      }
      
      .clickTo {
        display: flex;
        align-items: center;
        
        .chat {
          background: #ffe6e6;
          border-radius: 4px 0 0 4px;
          color: #fc3737;
        }
        
        .buy {
          background: #fc3737;
          border-radius: 0px 4px 4px 0px;
          color: #ffffff;
        }
        
        div {
          width: 125px;
          height: 55px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          line-height: 23px;
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
  }
}

.goods-tabs {
  width: 100%;
  padding: 0 30px 30px;
  box-sizing: border-box;
  
  /deep/ .el-tabs__nav-wrap::after {
    height: 0 !important;
  }
  
  /deep/ .el-tabs__active-bar {
    height: 0 !important;
  }
  
  /deep/ .el-tabs__item.is-active {
    color: #fc3737 !important;
  }
  
  /deep/ .el-tabs__item {
    color: #999999;
    font-size: 32px;
    margin-right: 40px;
  }
  
  /deep/ .el-tabs__item:hover {
    color: #fc3737 !important;
  }
}

// 服务详情
.box-about {
  padding-left: 8px;
  box-sizing: border-box;
  
  .title {
    font-weight: bold;
    color: #000000;
    line-height: 38px;
    font-size: 20px;
    margin-top: 30px;
    position: relative;
    
    &::after {
      content: "";
      position: absolute;
      left: -6px;
      width: 4px;
      height: 20px;
      top: 50%;
      transform: translateY(-50%);
      background: #fc3737;
    }
  }
  
  .content {
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    font-size: 14px;
    margin-top: 20px;
  }
  
  .txt-info {
    margin-top: 20px;
    border-radius: 8px;
    overflow: hidden;
    box-sizing: border-box;
    // border-right: 1px solid #d9d9d9;
    .txt_itemss {
      .left {
        border-radius: 0px 0px 0px 8px !important;
        height: 50px !important;
      }
    }
    
    .txt-border {
      border-radius: 8px 0px 0px 0px;
      border-top: 1px solid #d9d9d9;
      border-left: 1px solid #d9d9d9;
      overflow: hidden;
    }
    
    .txt-border2 {
      border-radius: 0px 8px 0px 0px !important;
    }
    
    .txt-border1 {
      border-radius: 0px 0px 0px 8px;
      border-bottom: 1px solid #d9d9d9;
      border-left: 1px solid #d9d9d9;
      overflow: hidden;
    }
    
    .txt-borders {
      border: 1px solid #d9d9d9;
      border-right: none;
    }
    
    //
    .right-txt {
      border-left: 1px solid #d9d9d9;
    }
    
    .txt-left {
      height: 150px !important;
    }
    
    .txt_items {
      display: flex;
      box-sizing: border-box;
      // width: 100%;
      .left {
        width: 274px;
        background: #ffffff; //fffae7
        display: flex;
        height: 50px;
        justify-content: center;
        align-items: center;
      }
      
      .right {
        width: calc(100% - 258px);
        box-sizing: border-box;
        
        .right-lable-border {
          border-bottom: none !important;
        }
        
        .right-lable {
          display: flex;
          height: 50px;
          border-bottom: 1px solid #d9d9d9;
          box-sizing: border-box;
          width: 100%;
          
          .name {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 297px;
          }
          
          .types {
            // width: 277px;
            width: calc(100% - 297px);
            height: 100%;
            background: #f5faff;
            border-left: 1px solid #d9d9d9;
            border-right: 1px solid #d9d9d9;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
  
  .box-tabs {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    
    .tabs-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 281px;
      height: 167px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #cdcdcd;
      margin-right: 53px;
      
      &:last-of-type {
        margin-right: 0 !important;
      }
      
      .num {
        font-weight: bold;
        color: #000000;
        line-height: 38px;
        font-size: 32px;
        margin-top: 14px;
      }
    }
  }
  
  //
  .txt-items {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .txt {
    display: flex;
    margin-top: 20px;
    
    .items-last {
      width: 100% !important;
    }
    
    .items {
      width: 25%;
      font-weight: 400;
      color: #999999;
      line-height: 23px;
      font-size: 20px;
      margin-bottom: 20px;
      //   &:last-of-type {
      //     width: 100%;
      //   }
    }
    
    .desc {
      font-weight: 400;
      color: #999999;
      line-height: 23px;
      font-size: 20px;
      margin-bottom: 40px;
    }
  }
}

.service-amount-option {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
  padding: 16px 30px;
  border: 1px solid #fc3737;
  border-radius: 5px;
  cursor: pointer;
  
  &.hover, &:hover {
    background: rgba(252, 55, 55, 0.12);
  }
  
  & + .service-amount-option {
    margin-top: 10px;
  }
  
  .price {
    display: flex;
    align-items: baseline;
    
    span {
      color: #fc3737;
      font-size: 16px;
      
      & + span {
        margin-left: 2px;
      }
      
      &.price-number {
        font-size: 24px;
        font-weight: bold;
        font-family: din-bold, serif;
        margin-left: -2px;
      }
    }
  }
  
  .tips {
    display: flex;
    align-items: center;
    color: #999999;
    font-size: 14px;
    
    img {
      height: 16px;
      margin-left: 4px;
    }
  }
  
  .wm-radio {
    position: absolute;
    right: 0;
    top: 0;
    background: #fc3737;
    border-radius: 0 0 0 100%;
    width: 24px;
    height: 24px;
  }
  
  .wm-radio::after {
    content: "";
    box-sizing: border-box;
    display: inline-block;
    width: 6px;
    height: 10px;
    border: 2px solid white;
    border-top: 0;
    border-left: 0;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translateY(-50%) rotate(45deg);
  }
}

</style>
