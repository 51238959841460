<template>
  <div class="Evaluates">
    <div class="User-info">
      <el-avatar :size="55" :src="item.avatarImg"></el-avatar>
      <div class="NickName">{{item.nick_name}}</div>
      <!-- <div class="typeOrder">回头客 5单</div> -->
    </div>
    <div class="Evaluates-rate">
      <el-rate v-model="item.score" disabled></el-rate>
      {{item.score+'分'}}
    </div>
    <div class="Evaluate-form">
      <div class="txt">
        <div class="title">服务态度:</div>
        <div class="desc">{{item.attitude}}</div>
      </div>
      <div class="txt">
        <div class="title">专业能力:</div>
        <div class="desc">{{item.speciality}}</div>
      </div>
      <div class="txt">
        <div class="title">交付质量:</div>
        <div class="desc">{{item.quality}}</div>
      </div>
    </div>
    <div class="Evaluate-day">
      {{formatDate(item.create_time)}}
      <!--<span>所雇服务：{{serviceTitle}}</span>-->
    </div>
    <!-- <div class="Evaluate-border"></div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    serviceTitle: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {
        return () => {
          return {};
        };
      },
    },
  },
};
</script>
<style lang="less" scoped>
.Evaluates {
  border-bottom: 1px solid #dfe3ea;
  &:last-of-type {
    border: none !important;
  }
  .Evaluates-rate {
    margin-top: 7px;
    // cursor: pointer;
    color: #ffa902;
    line-height: 23px;
    font-size: 20px;
    display: flex;
    /deep/ .el-rate__icon {
      font-size: 23px;
    }
  }
  .User-info {
    display: flex;
    align-items: center;
    .NickName {
      color: #000000;
      line-height: 23px;
      font-size: 18px;
      margin-left: 20px;
    }
    .typeOrder {
      width: 84px;
      height: 27px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #ffa902;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      color: #ffb324;
      line-height: 22px;
      font-size: 14px;
      margin-left: 15px;
    }
  }
  .Evaluate-form {
    .txt {
      display: flex;
      align-items: center;
      margin-top: 14px;
      .title {
        color: #000000;
        line-height: 19px;
        font-size: 16px;
      }
      .desc {
        color: #000000;
        line-height: 23px;
        font-size: 16px;
        margin-left: 6px;
      }
    }
  }
  .Evaluate-day {
    font-weight: 400;
    color: rgba(153, 153, 153, 0.6);
    line-height: 18px;
    font-size: 14px;
    margin-top: 20px;
    span {
      font-weight: 400;
      color: #999999;
      line-height: 18px;
      font-size: 14px;
      margin-left: 20px;
    }
  }
  //   .Evaluate-border {
  //     width: 100%;
  //     height: 1px;
  //     border-bottom: 1px solid #dfe3ea;
  //     padding-bottom: 40px;
  //   }
  //   .Evaluate:last-of-type {
  //     height: 0 !important;
  //   }
}
</style>
