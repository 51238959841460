<template>
  <div class="Evaluate">
    <!-- 筛选条件 -->
    <div class="Sort">
      <!-- 选项卡 -->
      <!-- <div class="Sort-tabs">
        <div class="Sort-item" :class="index==SortTabsIndex?'Sort-item-active':''" v-for="(item,index) in SortTabs" :key="index" @click="SortTabsClick(index)">{{item.name}} ( {{ item.num }} )</div>
      </div>-->
      <!-- 排序 -->
      <!-- <div class="Sort-type">
        <div class="Sort-item" v-for="(item,index) in SortType" :key="index" :class="index==SortIndex?'Sort-item-active':''" @click="SortClick(index)">
          {{item.name}}
          <el-divider direction="vertical" v-if="(index+1)!=SortType.length"></el-divider>
        </div>
      </div>-->
    </div>
    <!-- 列表 -->
    <Evaluate v-for="(item,index) in evaluate" :key="index" :item="item" :serviceTitle="serviceTitle" />
  </div>
</template>
<script>
import Evaluate from "@/components/Evaluate/index.vue";
export default {
  data() {
    return {
      SortType: [
        {
          name: "默认排序",
        },
        {
          name: "最新评价",
        },
      ],
      SortTabs: [
        {
          name: "全部",
          num: 5,
        },
        {
          name: "好评",
          num: 5,
        },
        {
          name: "中评",
          num: 0,
        },
        {
          name: "差评",
          num: 0,
        },
      ],
      SortIndex: 0,
      SortTabsIndex: 0,
    };
  },
  props: {
    evaluate: {
      type: Array,
      default: () => {
        return () => {
          return [];
        };
      },
    },
    serviceTitle: {
      type: String,
      default: "",
    },
  },
  components: {
    Evaluate,
  },
  methods: {
    SortClick(index) {
      this.SortIndex = index;
    },
    SortTabsClick(index) {
      this.SortTabsIndex = index;
    },
  },
};
</script>
<style lang="less" scoped>
.Evaluate {
  .Sort {
    display: flex;
    // padding: 0 40px;
    // box-sizing: border-box;
    .Sort-tabs {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 20px;
      .Sort-item {
        // width: 156px;
        min-width: 86px;
        height: 32px;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #cdcdcd;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        font-size: 12px;
        margin-right: 20px;
        cursor: pointer;
        box-sizing: border-box;
        &:hover {
          background: #fc3737;
          color: #ffffff;
          border: none;
        }
      }
      .Sort-item-active {
        background: #fc3737 !important;
        color: #ffffff !important;
        border: none;
      }
    }
    .Sort-type {
      display: flex;
      align-items: center;
      .Sort-item {
        color: #999999;
        line-height: 18px;
        font-size: 12px;
        cursor: pointer;
        &:hover {
          color: #333333;
        }
      }
      .Sort-item-active {
        color: #333333 !important;
      }
    }
  }
  .Evaluates {
    padding: 20px;
    box-sizing: border-box;
  }
}
</style>