 <!-- 
     需求大厅 详情页 组件 
     对接 服务流程
  -->
<template>
  <div class="FlowPath">
    <div class="Flow">
      <!-- 
            逻辑
            每行显示三个箱子  二个箭头  顺序 
      -->
      <div class="Flow-item">
        <div class="Flow-items">
          <div class="Flow-img">
            <svg width="160" height="160" viewBox="-50-50 300 300">
              <polygon class="triangle" stroke-linejoin="round" points="100,0 0,200 200,200" />
            </svg>
          </div>
          <div class="Folw-box">
            <div class="Folw-filter"></div>
            <div class="Folw-info">
              <div class="Folw-index">01</div>
              <div class="Folw-txt">
                <div class="Folw-title">服务商选择</div>
                <div class="Folw-desc">根据个人需求选择合 适的服务商</div>
              </div>
            </div>
          </div>
        </div>
        <div class="ArrowLeft">
          <img src="@/assets/imgs/demand/Arrow.png" alt />
        </div>
      </div>
      <!-- 2 -->
      <div class="Flow-item">
        <div class="Flow-items">
          <div class="Flow-img">
            <svg width="190" height="190" viewBox="-50-50 300 300">
              <polygon class="triangle" stroke-linejoin="round" points="100,0 0,200 200,200" />
            </svg>
          </div>
          <div class="Folw-box">
            <div class="Folw-filter"></div>
            <div class="Folw-info">
              <div class="Folw-index">02</div>
              <div class="Folw-txt">
                <div class="Folw-title">沟通确认需求</div>
                <div class="Folw-desc">与服务商精选深度沟 通确认需求</div>
              </div>
            </div>
          </div>
        </div>
        <div class="ArrowLeft">
          <img src="@/assets/imgs/demand/Arrow.png" alt />
        </div>
      </div>
      <!-- 3 -->
      <div class="Flow-item">
        <div class="Flow-items">
          <div class="Flow-img">
            <svg width="190" height="190" viewBox="-50-50 300 300">
              <polygon class="triangle" stroke-linejoin="round" points="100,0 0,200 200,200" />
            </svg>
          </div>
          <div class="Folw-box">
            <div class="Folw-filter"></div>
            <div class="Folw-info">
              <div class="Folw-index">03</div>
              <div class="Folw-txt">
                <div class="Folw-title">确定雇佣时段</div>
                <div class="Folw-desc">根据工作量确认雇佣 时间</div>
              </div>
            </div>
          </div>
        </div>
        <div class="ArrowBottom">
          <img src="@/assets/imgs/demand/Arrow.png" alt />
        </div>
      </div>
      <!-- 4 -->
      <div class="Flow-item">
        <div class="Flow-items">
          <div class="Flow-img">
            <svg width="190" height="190" viewBox="-50-50 300 300">
              <polygon class="triangle" stroke-linejoin="round" points="100,0 0,200 200,200" />
            </svg>
          </div>
          <div class="Folw-box">
            <div class="Folw-filter"></div>
            <div class="Folw-info">
              <div class="Folw-index">06</div>
              <div class="Folw-txt">
                <div class="Folw-title">交易完成评价</div>
                <div class="Folw-desc">根据合作满意度对服 务商进行评价</div>
              </div>
            </div>
          </div>
        </div>
        <div class="ArrowRight">
          <img src="@/assets/imgs/demand/Arrow.png" alt />
        </div>
      </div>
      <!-- 5 -->
      <div class="Flow-item">
        <div class="Flow-items">
          <div class="Flow-img">
            <svg width="190" height="190" viewBox="-50-50 300 300">
              <polygon class="triangle" stroke-linejoin="round" points="100,0 0,200 200,200" />
            </svg>
          </div>
          <div class="Folw-box">
            <div class="Folw-filter"></div>
            <div class="Folw-info">
              <div class="Folw-index">05</div>
              <div class="Folw-txt">
                <div class="Folw-title">等待交付验收</div>
                <div class="Folw-desc">服务确认验收，将托 管在平台的资金付款 给服务商，保障服务 交付</div>
              </div>
            </div>
          </div>
        </div>
        <div class="ArrowRight">
          <img src="@/assets/imgs/demand/Arrow.png" alt />
        </div>
      </div>
      <!-- 6 -->
      <div class="Flow-item">
        <div class="Flow-items">
          <div class="Flow-img">
            <svg width="190" height="190" viewBox="-50-50 300 300">
              <polygon class="triangle" stroke-linejoin="round" points="100,0 0,200 200,200" />
            </svg>
          </div>
          <div class="Folw-box">
            <div class="Folw-filter"></div>
            <div class="Folw-info">
              <div class="Folw-index">04</div>
              <div class="Folw-txt">
                <div class="Folw-title">服务商开始工作</div>
                <div class="Folw-desc">确定雇主需求，核实之后 无误，开始计时工作</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.triangle {
  fill: #3d8bff;
  stroke-width: 30;
  stroke: #3d8bff;
}
.Flow-img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
}
.ArrowLeft {
  width: 49px;
  height: 44px;
  margin-left: 38px;
  margin-right: 40px;
  img {
    width: 100%;
    height: 100%;
    margin-top: 73px;
  }
}
.ArrowRight {
  width: 49px;
  height: 44px;
  margin-left: 38px;
  margin-right: 40px;
  margin-top: 138px;
  transform: rotate(180deg);
  img {
    width: 100%;
    height: 100%;
  }
}
.ArrowBottom {
  width: 49px;
  height: 44px;
  position: absolute;
  left: 75px;
  transform: rotate(90deg);
  bottom: -145px;
  img {
    width: 100%;
    height: 100%;
  }
}
.FlowPath {
  width: 100%;
  padding: 40px 0;
  .Flow {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .Flow-item {
      display: flex;
      align-items: center;
      position: relative;
      &:nth-child(n + 4) {
        margin-top: 160px;
      }
    }
    .Flow-items-top {
      margin-top: 160px;
    }
    .Flow-items {
      width: 200px;
      height: 184px;
      position: relative;
      cursor: pointer;
      //   background: url("../../assets/imgs/demand/FlowPath.png") no-repeat center;
      //   background-size: cover;
      .Flow-img {
        // width: 100%;
        // height: 100%;
        // position: absolute;
        // top: 0;
        // left: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .Folw-box {
        width: 100%;
        height: 100%;
        box-shadow: inset 4px 4px 7px 2px #f1f6ff;
        border-radius: 20px 20px 20px 20px;
        position: relative;
        z-index: 6;
        top: 70px;
        box-sizing: border-box;
        overflow: hidden;
        .Folw-filter {
          background: rgba(234, 242, 255, 0.4);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(10px);
        }
        .Folw-info {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 3;
          .Folw-index {
            font-weight: bold;
            font-size: 36px;
            text-align: center;
            color: #ffffff;
            line-height: 42px;
            margin-top: 6px;
          }
          .Folw-txt {
            text-align: center;
            margin: 20px auto 0;
            max-width: 140px;
          }
          .Folw-title {
            color: #000000;
            font-weight: 400;
            color: #000000;
            line-height: 19px;
            font-size: 16px;
            text-align: left;
            margin: 0 auto;
            white-space: nowrap;
          }
          .Folw-desc {
            font-size: 14px;
            margin-top: 10px;
            text-align: left;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}
</style>