<template>
  <div class="html" v-html="Html"></div>
</template>
<script>
export default {
  props: {
    Html: {
      type: String,
      default: "",
    },
  },
};
</script>