<!-- 
   放大镜组件
   用于需求大厅详情页面
   https://zhwpic.zuhaowan.com/images/game_data_img/2021-01-20/6007de8d340f1.jpg
 -->
 
<template>
  <div class="Magnifier">
    <div class="Magnifier-img">
      <el-image :src="CoverUrl" style="width: 100%">
        <div slot="placeholder" class="image-slot">
        </div>
      </el-image>
<!--      <img class="leftImg" :src="CoverUrl" alt />-->
      <!-- 鼠标层罩 -->
      <!-- <div v-show="topShow" class="top" :style="topStyle"></div> -->
      <!-- 最顶层覆盖了整个原图空间的透明层罩 -->
      <!-- <div class="maskTop" @mouseenter="enterHandler" @mousemove="moveHandler" @mouseout="outHandler"></div> -->
    </div>
    <!-- <div v-show="rShow" class="right">
      <img :style="r_img" class="rightImg" :src="CoverUrl" alt />
    </div>-->
  </div>
</template>
 
<script>
export default {
  data() {
    return {
      topStyle: { transform: "" },
      r_img: {},
      topShow: false,
      rShow: false,
      showBg: true
    };
  },
  props: {
    CoverUrl: {
      type: String,
      default: "",
    },
  },
  methods: {
    // 鼠标进入原图空间函数
    enterHandler() {
      this.$emit("GetzIndex", false);
      // 层罩及放大空间的显示
      this.topShow = true;
      this.rShow = true;
    },
    // 鼠标移动函数
    moveHandler(event) {
      // 鼠标的坐标位置
      let x = event.offsetX;
      let y = event.offsetY;
      //   console.log(event, "event");
      // 层罩的左上角坐标位置，并对其进行限制：无法超出原图区域左上角
      let topX = x - 100 < 0 ? 0 : x - 100;
      let topY = y - 100 < 0 ? 0 : y - 100;

      // 对层罩位置再一次限制，保证层罩只能在原图区域空间内
      if (topX > 397) {
        topX = 397;
      }
      if (topY > 257) {
        topY = 257;
      }
      // 通过 transform 进行移动控制
      this.topStyle.transform = `translate(${topX}px,${topY}px)`;
      //   console.log(this.topStyle.transform, "this.topStyle");
      this.r_img.transform = `translate(-${2 * topX}px,-${2 * topY}px)`;
    },
    // 鼠标移出函数
    outHandler() {
      // 控制层罩与放大空间的隐藏
      this.topShow = false;
      this.rShow = false;
      this.$emit("GetzIndex", true);
    },
    handleLoad() {
      this.showBg = false
      console.log('image loaded')
    }
  },
};
</script>
 
 
<style lang="less" scoped>
.Magnifier {
  //height: 465px;
  //   width: 594px;
  //position: relative;
  //z-index: 6;
  // 放大镜标签
  .Magnifier-img {
    //width: 871px;
    //height: 465px;

    .el-image__inner {
      border-radius: 20px;
    }
    .image-slot {
      width: 871px;
      height: 465px;
      background: #d9d9d9;
      border-radius: 20px;
    }
    .maskTop {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    }
    /* 原图的显示 */
    .leftImg {
      width: 100%;
      height: 100%;
      display: inline-block;
    }
  }
}

/* 放大的图片，通过定位将左上角定位到(0,0) */
.rightImg {
  display: inline-block;
  width: 1188px;
  height: 914px;
  position: absolute;
  top: 0;
  left: 0;
}
/* 右边的区域图片放大空间 */
.right {
  width: 400px;
  height: 457px;
  position: relative;
  overflow: hidden;
  margin-left: 20px;
  background: #d9d9d9;
}
/* 层罩，通过定位将左上角定位到(0,0) */
.top {
  width: 200px;
  height: 200px;
  background-color: lightcoral;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
}
</style>